import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Slider from 'react-slick'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import TextLockup from 'src/components/TextLockup'
import Button from 'src/components/Button'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ThemeSelector from 'src/components/ThemeSelector'
import Grid from 'src/components/Grid'
import { typography, globals, mq, animations } from 'src/styles'

const Wrapper = styled.div`
  .slick-track {
    display: flex !important;
    align-items: stretch;
  }
  .slick-slide {
    float: none !important;
    height: auto !important;
    > div {
      height: 100%;
    }
  }
`

const IntroWrap = styled(TextLockup)`
  ${ globals.verticalSpacing('margin-bottom', 0.25) }
`

const ColumnsWrapper = styled.div`
  ${ mq.smallAndBelow } {
    overflow-x: auto;
    > div {
      width: 275%;
    }
  }
`

export const ReviewMeta = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 1em;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  ${ typography.body }
  ${ mq.largerAndUp } {
    margin-top: 1.5em;
  }
  p {
    ${ typography.body }
    font-weight: ${ typography.medium };
    margin: 0;
    ${ mq.extraLargeAndUp } {
      ${ typography.bodyMedium }
      font-weight: ${ typography.medium };
    }
    &.title {
      color: var(--text-color);
    }
    &.subtitle {
      color: var(--light-text-color);
    }
  }
`

const ReviewText = styled.div`
  > p {
    ${ typography.h4 }
    flex-shrink: 0;
    max-width: 100%;
    ${ mq.largerAndUp } {
      ${ typography.h3 }
    }
  }
`

const ReviewSlide = styled(Grid)`
  height: 100%;
  width: 100%;
`

const Slide = styled.div`
  height: 100%;
`

const SlideImage = styled.div`
  width: 100%;
  .gatsby-image-wrapper {
    ${ mq.largeAndUp } {
      height: 100%;
    }
  }
`

const SlideContent = styled.div`
  width: 100%;
  min-height: 18vw;
  padding: var(--site-margins);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  ${ mq.largeAndUp } {
    height: 100%;
    padding: calc(var(--site-margins) * .75);
  }
  ${ ReviewText } {
    margin-top: -.2em;
  }
`

const ReviewsSlider = styled(Slider)`
  .slick-slide.slick-current {
    ${ ReviewText } {
      > * {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
        animation: ${ animations.transformIn } .4s .2s ease-in-out forwards;
        &.review-meta {
          animation-delay: .3s;
        }
      }
    }
  }
`

const Eyebrow = styled.div`
  ${ typography.body }
  font-weight: ${ typography.bold };
  color: var(--main-color);
  margin-bottom: .25em;
  ${ mq.extraLargeAndUp } {
    margin-bottom: 1.3em;
    ${ typography.bodyMedium }
    font-weight: ${ typography.bold };
  }
`

const SliderControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  z-index: 3;
  margin-top: 6px;
  margin-bottom: -12px;
  ${ mq.largerAndUp } {
    margin-top: 20px;
  }
  .dots {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .review-pagination {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: -16px;
    .button {
      &:hover {
        color: var(--main-color);
      }
    }
    ${ mq.extraLargeAndUp } {
      margin-left: -20px;
    }
  }
`

const Dot = styled.div`
  width: 6px;
  height: 6px;
  background: currentcolor;
  border-radius: 50%;
  transition: opacity ${ animations.mediumSpeed } ease-in-out;
  margin-left: 14px;
  ${ ({ current }) => current ? `
    opacity: 1;
  ` : `
    opacity: .3;
  ` }
`

export const ThumbnailWrapper = styled.div`
  width: ${ ({ type }) => type === 'individual' ? '80px' : '120px' };
  flex-grow: 0;
`

export const ReviewImage = styled(Image)`
  border-radius: 4px;
  img {
    border-radius: 4px;
  }
`

const Reviews = ({
  className,
  nextTheme,
  prevTheme,
  setTheme,
  theme,
  isFirstSection,
  headline,
  reviewsList,
  type,
  id
}) => {
  const slider = useRef(null)
  const settings = {
    arrows: false,
    infinite: true,
    autoplay: true,
    fade: true,
    swipe: true,
    speed: 500,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    adaptiveHeight: true
  }

  if (type === 'columns') {
    return (
      <Wrapper
        className={className}
        prevTheme={prevTheme}
        nextTheme={nextTheme}
        setTheme={theme || 'lightGrey'}
        nextTheme={nextTheme}
        isFirstSection={isFirstSection}
        as={Section}
        id={id}
      >
        <Grid small='m [1] m'>
          <IntroWrap
            headline={headline || 'Testimonials'}
            headlineSize='h1'
            showHr
          />
        </Grid>

        <ColumnsWrapper>
          <Grid small='m [1] m'>
            <Grid
              small='[1] [1] [1] [1]'
              medium='[1] [1]'
              large={reviewsList.length < 4 ? '[1] [1] [1]' : '[1] [1] [1] [1]'}
              vAlign='top'
              colGap={['18px', '26px', '30px']}
              rowGap='40px'
              delay={2}
              as={ScrollEntrance}
            >
            {reviewsList.map((review, index) => (
              <div key={review._key}>
                <Image
                  image={review?.thumbnail?.asset?.gatsbyImageData}
                  ratio={4 / 3}
                  alt={review.title}
                />
                <p>
                  {review.reviewText}
                </p>
                <div>
                  {review.title && (<p css={css`${ typography.bodySmall } font-weight: ${ typography.medium }; margin: 0; color: var(--main-color);`}>{review.title}</p>)}
                  {review.subtitle && (<p css={css`${ typography.bodySmall } font-weight: ${ typography.medium }; margin: 0; color: var(--light-text-color);`}>{review.subtitle}</p>)}
                </div>
              </div>
            ))}
            </Grid>
          </Grid>
        </ColumnsWrapper>
      </Wrapper>
    )
  }

  return (
    <Wrapper
      className={className}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
    >
      <ThemeSelector
        setTheme={setTheme || 'lightGrey'}
      >
        <ReviewsSlider ref={slider} {...settings}>
          {reviewsList.map((review, index) => (
            <Slide key={review._key} firstSlide={index === 0}>
              <ReviewSlide small='[1]' large='[1] [1]' vAlign='stretch'>
                <SlideImage type={type}>
                  <Image
                    image={review?.thumbnail?.asset?.gatsbyImageData}
                    ratio={1}
                    alt={review.title}
                  />
                </SlideImage>
                <SlideContent>
                  <Eyebrow>{headline || 'Testimonials'}</Eyebrow>
                  <ReviewText className='review-text'>
                    <p>{review.reviewText}</p>
                    <ReviewMeta className='review-meta'>
                      <div>
                        {review.title && (<p className='title'>{review.title}</p>)}
                        {review.subtitle && (<p className='subtitle'>{review.subtitle}</p>)}
                      </div>
                    </ReviewMeta>
                  </ReviewText>
                  {reviewsList.length > 1 && (
                    <SliderControls>
                      <div className='review-pagination'>
                        <Button setTheme='transparent' icon='arrow_back' shape='circle' onClick={() => slider?.current?.slickPrev()} />
                        <Button setTheme='transparent' icon='arrow_forward' shape='circle' onClick={() => slider?.current?.slickNext()} />
                      </div>
                      <div className='dots'>
                        {reviewsList.map((item, dotIndex) => {
                          return (
                            <Dot current={index === dotIndex} />
                          )
                        })}
                      </div>
                    </SliderControls>
                  )}
                </SlideContent>
              </ReviewSlide>
            </Slide>
          ))}
        </ReviewsSlider>
      </ThemeSelector>
    </Wrapper>
  )
}

export default Reviews
